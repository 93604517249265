<template>
  <div id="app">
    <h1 class="header">POST!</h1>
    <div class="new-post-area">
      <b-card class="new-post-card">
        <b-form class="new-post-form" @submit="newPost">
          <b-form-select v-model="post.type" :options="[{value: 'image', text: 'picture'},{value: 'text', text: 'text'}]"></b-form-select>
          <b-form-input v-model="post.title" placeholder="Title" required></b-form-input>
          <b-form-input v-model="post.username" placeholder="YourName" required></b-form-input>
          <b-form-textarea v-model="post.content" placeholder="Content/PictureURL" required></b-form-textarea>
          <b-button type="submit" variant="primary">Post!</b-button>
        </b-form>
      </b-card>
    </div>
    <div class="card-area" v-for="(post, idx) in posts" :key="idx">
      <b-card
          v-if="post.type === 'image'"
          :img-src="post.content"
          img-alt="picture"
          img-top
          :title="post.title"
          class="post-card"
      >
        <b-card-text class="post-username">— {{post.username}}</b-card-text>
        <b-button variant="outline-primary" @click="vote('upvote', idx)">👍 {{post.upvote}}</b-button>
        <b-button class="vote-down" variant="outline-primary" @click="vote('downvote', idx)">👎 {{post.downvote}}</b-button>
      </b-card>
      <b-card
          v-else
          class="post-card"
          :title=post.title
      >
        <b-card-text>{{ post.content }}</b-card-text>
        <b-card-text class="post-username">— {{post.username}}</b-card-text>
        <b-button variant="outline-primary" @click="vote('upvote', idx)">👍 {{post.upvote}}</b-button>
        <b-button class="vote-down" variant="outline-primary" @click="vote('downvote', idx)">👎 {{post.downvote}}</b-button>
      </b-card>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'App',
  components: {
  },
  created() {
    this.fetchPosts();
  },
  data: () => {
    return {
      posts: [],
      post: {
        content: '',
        username: '',
        title: '',
        type: 'text',
        upvote: 0,
        downvote: 0
      }
    }
  },
  methods: {
    vote(updown, postIndex) {
      axios.put('https://xikai_worker.xikai.workers.dev/', {
        updown,
        postIndex
      }, {
        headers: {
          'content-type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS' ,
        }
      }).then((res) => {
        if (res.data.status === 'success') {
          this.fetchPosts();
        } else {
          alert('vote failed!');
        }
      });
    },
    fetchPosts() {
      let that = this;
      axios.get('https://xikai_worker.xikai.workers.dev/')
          .then((res) => {
            if (res.data.status === 'success') {
              that.posts = JSON.parse(res.data.data);
            } else {
              alert('fetch all posts failed!');
            }
          });
    },
    newPost(e) {
      e.preventDefault();
      let that = this;
      axios.post('https://xikai_worker.xikai.workers.dev/',{
        ...that.post
      }, {
        headers: {
          'content-type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS' ,
        }
      }).then((res) => {
        if (res.data.status === 'success') {
          this.fetchPosts();
          this.post = {
            content: '',
            username: '',
            title: '',
            type: 'text',
            upvote: 0,
            downvote: 0
          };
        } else {
          alert('create new post failed!');
        }
      });
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.header {
  margin-top: 1rem;
  text-align: center;
  font-style: italic;

}
.new-post-area {
  max-width: 30rem;
  margin: 0 auto;
}
.new-post-card {
  margin: 1rem 1rem;
}
.new-post-form > * {
  margin-top: 1rem;
}
.post-username {
  text-align: right;
}
.card-area {
  max-width: 30rem;
  margin: 0 auto;
}
.post-card {
  margin: 1rem 1rem;
}
.vote-down {
  margin-left: 0.5rem;
}
</style>
